var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "소방 훈련 시나리오 상황 목록",
            merge: _vm.grid.merge,
            columns: _vm.grid.columns,
            data: _vm.traningEdu.scenarios,
            gridHeight: _vm.grid.height,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
            editable: _vm.editable && !_vm.disabled,
            selection: "multiple",
            rowKey: "sopFireFightingTraningScenarioResultId",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  _c(
                    "div",
                    [
                      _c(
                        "q-btn-group",
                        {
                          staticClass: "ColumInnerBtnGroup",
                          attrs: { outline: "" },
                        },
                        [
                          _vm.editable
                            ? _c("q-btn", {
                                staticClass: "ColumInnerBtn",
                                attrs: {
                                  label: "",
                                  icon: "add",
                                  color: "light-blue",
                                  "text-color": "white",
                                  align: "center",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.addRow(props)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("c-textarea-column", {
                    attrs: { editable: _vm.editable, col: col, props: props },
                    on: {
                      datachange: function ($event) {
                        return _vm.datachange(props, col)
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "suffixTitle" },
            [
              _c("br"),
              _c(
                "font",
                {
                  staticClass: "text-negative",
                  staticStyle: { "font-size": "0.8em", "font-weight": "300" },
                },
                [
                  _vm._v(
                    " ※ 계획수립 기본정보에서 시나리오를 입력하면 시나리오 상황 정보가 표시됩니다. "
                  ),
                  _c("br"),
                  _vm._v(
                    " ※ 계획수립 기본정보에서 입력한 시나리오 정보 외에 추가를 원할 경우 추가 버튼을 통해 입력바랍니다. "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "삭제", icon: "delete" },
                        on: { btnClicked: _vm.deleteTarget },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addTarget },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable && !_vm.disabled,
                        expression: "editable && !disabled",
                      },
                    ],
                    attrs: {
                      url: _vm.saveUrl,
                      isSubmit: _vm.isSave,
                      param: _vm.traningEdu.scenarios,
                      mappingType: "PUT",
                      label: "저장",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveInfo,
                      btnCallback: _vm.saveCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }