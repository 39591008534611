<template>
  <div>
    <c-table
      ref="table"
      title="소방 훈련 시나리오 상황 목록"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="traningEdu.scenarios"
      :gridHeight="grid.height"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="sopFireFightingTraningScenarioResultId"
    >
      <template slot="suffixTitle">
        <br/>
        <font class="text-negative" style="font-size:0.8em;font-weight:300;">
          ※ 계획수립 기본정보에서 시나리오를 입력하면 시나리오 상황 정보가 표시됩니다.
          <br/>
          ※ 계획수립 기본정보에서 입력한 시나리오 정보 외에 추가를 원할 경우 추가 버튼을 통해 입력바랍니다.
        </font>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="삭제" icon="delete" @btnClicked="deleteTarget" />
          <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addTarget" />
          <c-btn
            v-show="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="traningEdu.scenarios"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <div>
          <q-btn-group outline class="ColumInnerBtnGroup">
            <q-btn
              v-if="editable"
              label=""
              icon="add"
              color="light-blue"
              text-color="white"
              class="ColumInnerBtn"
              align="center"
              @click.stop="addRow(props)">
            </q-btn>
          </q-btn-group>
        </div>
        <c-textarea-column
          :editable="editable"
          :col="col"
          :props="props"
          @datachange="datachange(props, col)"
        />
      </template>
    </c-table>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingTraningEduPlanScenario',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',
      }),
    },
    traningEdu: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',  // 소방 훈련·교육 계획/결과 일련번호
        plantCd: '',  // 사업장코드
        traninigEduDt: '',  // 훈련·교육 일시
        sopFireFightingTraningEduStepCd: '',  // 소방 훈련·교육 계획/결과 진행상태
        traninigEduName: '',  // 소방 훈련·교육 계획/결과명
        locationName: '',  // 장소
        sopFireFightingTraningEduTargetCd: '',  // 소방 훈련·교육 대상
        sopFireFightingTraningEduPracticeCd: '',  // 소방 훈련·교육 실습
        sopFireFightingTraningEduTheoryCd: '',  // 소방 훈련·교육 이론
        deptCd: '',  // 주관부서
        fireSafetyManagerId: '',  // 소방안전관리자
        fireSafetyManagerQualitClassCd: '',  // 소방안전관리자 자격구분
        fireSafetyManagerNote: '',  // 소방안전관리자 비고
        sopFireFightingTraningEduFormCd: '',  // 소방 훈련 형태
        participatingInstitutions: '',  // 참여기관
        sopFireFightingTraningScenarioId: '',  // 소방 훈련 시나리오 일련번호
        traningPlan: '',  // 훈련계획
        eduPlan: '',  // 교육계획
        evaluationPlanDt: '',  // 평가계획일시
        evaluationUserId: '',  // 평가자
        evaluationMethod: '',  // 평가방법
        evaluationDt: '',  // 평가일시
        educationInstructorName: '',  // 교육강사
        totalNumber: '',  // 총원
        attendNumber: '',  // 참여인원
        nonAttendNumber: '',  // 미참석
        selfTotalNumber: '',  // 자위소방대 총원
        selfLeaderId: '',  // 자위소방대 대장명
        selfLeaderPhoneNumber: '',  // 자위소방대 대장 연락처
        selfUnitLeaderNumber: '',  // 자위소방대 부대장 인원
        selfNotiContactNumber: '',  // 자위소방대 통보연락 인원
        selfInitFireExtingNumber: '',  // 자위소방대 초기소화 인원
        selfEvacuGuidNumber: '',  // 자위소방대 피난유도 인원
        selfEmergencyContactNumber: '',  // 자위소방대 비상연락 인원
        mainContent: '',  // 주요내용
        mainAchievements: '',  // 주요성과
        problem: '',  // 문제점
        supplementaryMatters: '',  // 보완사항
        actionTaken: '',  // 조치사항
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        users: [],
        scenarios: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'situationMessage', }
        ],
        columns: [
          {
            name: 'situationMessage',
            field: 'situationMessage',
            label: '상황메시지',
            align: 'left',
            sortable: true,
            style: 'width: 200px',
            type: 'custom',
          },
          {
            name: 'trainingTime',
            field: 'trainingTime',
            label: '훈련시간',
            align: 'left',
            sortable: true,
            style: 'width: 150px',
            type: 'textarea',
          },
          {
            name: 'trainingLocation',
            field: 'trainingLocation',
            label: '훈련위치<br/>(평가관 위치)',
            align: 'left',
            sortable: true,
            style: 'width: 120px',
            type: 'textarea',
          },
          {
            name: 'trainingSituation',
            field: 'trainingSituation',
            label: '훈련상황',
            align: 'left',
            sortable: true,
            style: 'width: 150px',
            type: 'textarea',
          },
          {
            name: 'trainingContents',
            field: 'trainingContents',
            label: '훈련내용<br/>(기대행동)',
            align: 'left',
            sortable: true,
            style: 'width: 350px',
            type: 'textarea',
          },
        ],
        height: '500px',
      },
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = this.contentHeight - 150
      if (height < 650) {
        height = 650
      }
      this.grid.height = String(height) + 'px'
    }
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFightingTraningEdu.scenario.list.url
      this.saveUrl = transactionConfig.fft.fireFightingTraningEdu.scenario.save.url
      this.deleteUrl = transactionConfig.fft.fireFightingTraningEdu.scenario.delete.url
      // code setting
      let height = this.contentHeight - 150
      if (height < 650) {
        height = 650
      }
      this.grid.height = String(height) + 'px'
      // list setting
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopFireFightingTraningEduId: this.popupParam.sopFireFightingTraningEduId
      };
      this.$http.request((_result) => {
        this.$set(this.traningEdu, 'scenarios', _result.data)
      },);
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    addRow(props) {
      this.trainingScenario.situations.splice(props.rowIndex, 0, {
        sopFireFightingTraningScenarioResultId: uid(),  // 소방 훈련 시나리오 결과 일련번호
        sopFireFightingTraningEduId: this.popupParam.sopFireFightingTraningEduId,  // 소방 훈련·교육 계획/결과 일련번호
        sopFireFightingTraningScenarioSituationId: '',  // 소방 훈련 시나리오 상황 일련번호
        situationMessage: '',  // 상황메시지
        trainingTime: '',  // 훈련시간
        trainingLocation: '',  // 훈련위치
        trainingSituation: '',  // 훈련상황
        trainingContents: '',  // 훈련내용
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    addTarget() {
      this.traningEdu.scenarios.splice(0, 0, {
        sopFireFightingTraningScenarioResultId: uid(),  // 소방 훈련 시나리오 결과 일련번호
        sopFireFightingTraningEduId: this.popupParam.sopFireFightingTraningEduId,  // 소방 훈련·교육 계획/결과 일련번호
        sopFireFightingTraningScenarioSituationId: '',  // 소방 훈련 시나리오 상황 일련번호
        situationMessage: '',  // 상황메시지
        trainingTime: '',  // 훈련시간
        trainingLocation: '',  // 훈련위치
        trainingSituation: '',  // 훈련상황
        trainingContents: '',  // 훈련내용
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    deleteTarget() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.traningEdu.scenarios = this.$_.reject(this.traningEdu.scenarios, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveInfo() {
      if (this.$comm.validTable(this.grid.columns, this.traningEdu.scenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>

<style lang="sass">
.fire-fighting-traning-edu-year-gubun-tr
  td
    text-align: center
    color: white !important
    font-size: 1rem !important
    font-weight: 600
  .text-align-right
    text-align: right
</style>